import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import {
  SectionHeader,
  SectionParagraph,
  SectionSkill,
} from "../components/SectionArticle"

const ResumePage = ({ data }) => {
  const filePdfUrl = data.file.publicURL
  const filePdfName = data.file.name

  return (
    <Layout classNameNav="bg-green-700 text-white">
      <Seo
        title="Syawal Jasira Resume Page of Personal and Portfolio Website"
        description="Syawal jasira resume, cv, skills, and projects information"
        siteUrl="https://www.syawaljasira.com/resume"
      />
      <div className="py-20 sm:py-28">
        <header>
          <SectionHeader>Resume</SectionHeader>
          <span className="text-center">
            <SectionParagraph>You can find my CV here.</SectionParagraph>
          </span>
        </header>
        <main>
          <div className="text-center">
            <button className="rounded-full border-2 border-green-700 text-green-900 hover:bg-green-700 hover:text-white py-1 px-5">
              <a href={filePdfUrl} alt={filePdfName} download>
                Curriculum Vitae
              </a>
            </button>
          </div>
          <div className="text-center pt-12">
            <SectionParagraph>Skills & Technology</SectionParagraph>
            <div className="flex flex-wrap w-full mx-auto justify-center">
              <SectionSkill tech="html" title="HTML5" desc="Advanced" />
              <SectionSkill tech="css" title="CSS3" desc="Advanced" />
              <SectionSkill
                tech="javascript"
                title="Javascript"
                desc="Advanced"
              />
              <SectionSkill tech="react" title="React" desc="Intermediate" />
            </div>
          </div>
        </main>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PdfQuery {
    file(relativePath: { eq: "Resume.pdf" }) {
      name
      publicURL
    }
  }
`

export default ResumePage
